import { useContext } from "react";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import { MapContext } from "../../Map";

import Button from "../../../Button/Button";

import styles from "./SearchControl.module.scss";

export interface SearchControlProps {
  dataQA: string;
  buttonStyle: "smallSquare" | "largeCircle";
  className?: string;
  onClick: () => void;
  project: string;
  hasInteracted: boolean;
  multipleResults: boolean;
  onClearClick: () => void;
  searchTerm?: string;
}

const SearchControl: React.FC<SearchControlProps> = ({
  dataQA,
  onClick,
  className,
  project,
  hasInteracted,
  multipleResults,
  searchTerm,
  onClearClick,
}) => {
  const { controlSize, controlTheme } = useContext(MapContext);

  // TODO: `metro_centre` case is a hotfix; will be resolved properly in DT-1595 (needs a backend update)
  const projectTitle =
    project === "metro_centre"
      ? "Metrocentre"
      : project
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(" ");

  const clearBtnSize = () => {
    switch (controlSize) {
      case "small":
        return "tiny";
      case "large":
        return "medium";
      default:
        return "small";
    }
  };

  return (
    <div
      className={classNames(
        className,
        styles.container,
        styles[controlTheme],
        styles[controlSize],
        {
          [styles.savedTerm]: searchTerm && multipleResults,
        },
      )}
    >
      <button
        data-qa={dataQA}
        className={classNames(styles.button)}
        onClick={onClick}
      >
        <Icon
          dataQA={`${dataQA}-icon`}
          type="SearchIcon"
          className={styles.svg}
        />
        <span className={styles.text}>
          {searchTerm && multipleResults
            ? searchTerm
            : `Search ${projectTitle}`}
        </span>
        <div
          className={classNames(styles.animationContainer, {
            [styles.interacted]: hasInteracted,
          })}
        >
          <div className={classNames(styles.circle, styles.red1)} />
          <div className={classNames(styles.circle, styles.purple1)} />
          <div className={classNames(styles.circle, styles.purple2)} />
          <div className={classNames(styles.circle, styles.white1)} />
        </div>
      </button>

      {searchTerm && multipleResults && (
        <Button
          dataQA="clear-btn"
          className={styles.clearButton}
          label="Clear"
          size={clearBtnSize()}
          rounded
          color={controlTheme === "light" ? "black" : "white"}
          rightIcon="CloseIcon"
          onClick={(e) => {
            e.stopPropagation();
            onClearClick();
          }}
        />
      )}
    </div>
  );
};

export default SearchControl;
