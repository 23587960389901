import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { useTransition, animated, config } from "react-spring";
import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import type { MapOptions, LngLatBoundsLike } from "mapbox-gl";
import type LivingMap from "@livingmap/core-mapping";

import {
  Map,
  Compass,
  Header,
  CentreControl,
  FloorSelector,
  PanControl,
  SearchControl,
  SearchResults,
  Keyboard,
  ZoomControl,
  LocationButton,
  LocationStatus,
  Modal,
} from "../../components";
import { useSession, useImagePreloader } from "../../hooks";
import {
  ConfigurationResponse,
  InteractionEventTypes,
  Feature,
  useLazyPostRouteQuery,
} from "../../redux/services/config";
import { PLUGIN_IDS } from "../../components/Map/plugins/types";
import FloorControl from "../../components/Map/plugins/floor-control";
import PositionPlugin from "../../components/Map/plugins/position-control";
import ClusteredPinPlugin from "../../components/Map/plugins/clustered-pin-control";
import {
  createLMFeatures,
  getBoundingBox,
  throttle,
  buildRouteShortlink,
  parseOpeningTimeString,
} from "../../utils";
import styles from "./BaseWithHeader.module.scss";
import IFrame from "../../components/IFrame/IFrame";
import Button from "../../components/Button/Button";
import ShareToMobileModal from "../../components/ShareToMobileModal/ShareToMobileModal";
import MoveDownView from "../../components/MoveDownView/MoveDownView";
import { useDispatch } from "react-redux";
import {
  setMoveDownPopups,
  setIsStepFreeRoute,
  setControlsAlwaysLow,
} from "../../redux/slices/applicationSlice";
import { useAppSelector } from "../../redux/hooks";
import { useOnlineMode } from "../../hooks/useOnlineMode";
import { Feature as GeoJsonFeature } from "geojson";
import RoutingPlugin from "../../components/Map/plugins/routing-control";
import { useDisplayRoute } from "../../hooks/useDisplayRoute";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import {
  ControlSizeScale,
  FullHdResolution,
  UISize,
  UITheme,
} from "../../components/types";
import { useMessagingProtocol } from "../../hooks/useMessagingProtocol";
import { MessageType } from "../../types/messagingProtocol";
import { useScreenDiagnostics } from "../../hooks/useScreenDiagnostics";
import MoveDownButton from "../../components/MoveDownButton/MoveDownButton";
import StepFreeChip from "../../components/StepFreeChip/StepFreeChip";
import SearchResultsCarousel from "../../components/Map/components/SearchResultsCarousel/SearchResultsCarousel";
import AssetInformation from "../../components/Map/components/AssetInformation/AssetInformation";
import { getFloorDifference } from "../../utils/getFloorDifference";
import {
  FeatureWithDistance,
  sortFeaturesByDistance,
} from "../../utils/sortFeaturesByDistance";

const DEFAULT_FEATURE_HORIZON_HEIGHT = 715;

export interface OnTouchHandlerOptions {
  featureID?: string | number;
  featureName?: string;
}

interface Props {
  data: ConfigurationResponse;
  features?: Feature[];
  uptime: string | null;
}

enum StepFreeStatus {
  SHOW = "show",
  HIDE = "hide",
  INACTIVE = "inactive",
}

const BaseWithHeader: React.FC<Props> = ({ data, features, uptime }) => {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const routing = queryParams.get("routing") !== "disable";
  const routeFeatureId = queryParams.get("feature");

  // config data
  const {
    components: { map_primary, body, handoff_bar, top_bar, iframe },
    languages,
    stylesheet,
    location,
    server,
    floors,
    project,
    map_key: mapboxApiKey,
    screen_id,
    display,
  } = data;

  const { controls: primaryControls, search } = map_primary;
  const controlSize: UISize = primaryControls.theme.size;
  const controlTheme: UITheme = primaryControls.theme.mode;
  const mapControlMarginBottom = primaryControls.theme.margin_bottom;
  const mapControlsAlwaysLow = primaryControls.theme.always_low;

  const { enable_images, pre_load_images } = search;
  const fullHD =
    display.resolution_x === FullHdResolution.x ||
    display.resolution_x === FullHdResolution.y;

  const noticeTimeout =
    map_primary.interaction.session_timeout -
    map_primary.interaction.session_timeout_notice;

  const activeLocationStyle = useMemo(() => {
    return {
      colour: "#4B82FF", // $blue-tone-600
      borderColour: "#FFF", // $white-tone
      displayPulse: true,
    };
  }, []);

  const dispatch = useDispatch();

  const { moveDownPopups, onlineMode, isStepFreeRoute, controlsAlwaysLow } =
    useAppSelector((state) => state.application);

  // refs
  const mapInstance = useRef<LivingMap | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const positionControlInstance = useRef<PositionPlugin | null>(null);
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);
  const routingControlInstance = useRef<RoutingPlugin | null>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  // state
  const [mapIsReady, setMapIsReady] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [userLocationStyle, setUserLocationStyle] =
    useState(activeLocationStyle);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInputActive, setSearchInputActive] = useState(true);
  const [zoomLimitReached, setZoomLimitReached] = useState({
    min: false,
    max: false,
  });
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isScreenIdModalOpen, setIsScreenIdModalOpen] = useState(false);
  const [isDiagnosticsModalOpen, setIsDiagnosticsModalOpen] = useState(false);
  const [stepFreeStatus, setStepFreeStatus] = useState<StepFreeStatus>(
    StepFreeStatus.INACTIVE,
  );

  const [footerHeight, setFooterHeight] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState<GeoJsonFeature | null>(
    null,
  );
  const [countdownTimeInSeconds, setCountdownTimeInSeconds] = useState(
    noticeTimeout / 1000,
  );
  const [locationBtnActive, setLocationBtnActive] = useState(true);
  const [carouselFeatures, setCarouselFeatures] = useState<
    FeatureWithDistance[]
  >([]);

  const operatingHours = selectedFeature?.properties?.opening_times || null;
  const codeLink = buildRouteShortlink(selectedFeature?.id, isStepFreeRoute);
  const formattedOperatingHours =
    typeof operatingHours === "string" || operatingHours === null
      ? parseOpeningTimeString(operatingHours)
      : operatingHours;
  const { amount, direction } = getFloorDifference(
    selectedFeature?.properties?.poi_floor_id,
    floors[map_primary.floor].id,
    floors,
  );

  const [routeRequestTrigger, routeResult] = useLazyPostRouteQuery();

  const { renderRoute } = useDisplayRoute({
    segments: routeResult.data?.segments,
    sequenceOrder: routeResult.data?.routeMetadata[0].sequenceOrder,
    routingControl: routingControlInstance.current,
  });

  const transitions = useTransition(searchIsActive, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: searchIsActive,
    config: {
      ...config.molasses,
      duration: 200,
    },
  });

  const { checkOnlineMode } = useOnlineMode(() =>
    handleTouchEvent(
      InteractionEventTypes.OFFLINE_SESSION,
      !!map_primary?.interaction?.session_logging,
      false,
    ),
  );

  const { imagesPreloaded } = useImagePreloader(features, pre_load_images);

  const { getScreenDiagnostics } = useScreenDiagnostics({
    timezone: location.timezone,
    displayResolutionX: display.resolution_x,
    displayResolutionY: display.resolution_y,
    language: languages[0],
    kioskIdOverlay: isScreenIdModalOpen,
    diagnosticsOverlay: isDiagnosticsModalOpen,
    uptime,
  });

  const handleMessagingProtocol = useCallback(
    (messageType: MessageType) => {
      switch (messageType) {
        case MessageType.RELOAD_WEBVIEW:
          return window.location.reload();
        case MessageType.OVERLAY_KIOSK_ID_SHOW:
          return !isScreenIdModalOpen && setIsScreenIdModalOpen(true);
        case MessageType.OVERLAY_KIOSK_ID_HIDE:
          return isScreenIdModalOpen && setIsScreenIdModalOpen(false);
        case MessageType.OVERLAY_DIAGNOSTICS_SHOW:
          return !isDiagnosticsModalOpen && setIsDiagnosticsModalOpen(true);
        case MessageType.OVERLAY_DIAGNOSTICS_HIDE:
          return isDiagnosticsModalOpen && setIsDiagnosticsModalOpen(false);
        default:
          break;
      }
    },
    [isDiagnosticsModalOpen, isScreenIdModalOpen],
  );

  useMessagingProtocol(
    data.screen_id,
    handleMessagingProtocol,
    getScreenDiagnostics,
  );

  const handleUserLocationStyle = useCallback(
    (isActive = true) => {
      setUserLocationStyle(
        isActive
          ? activeLocationStyle
          : {
              colour: "#BDBDBD", // $grey-tone-400
              borderColour: "#757575", // $grey-tone-600
              displayPulse: false,
            },
      );
    },
    [activeLocationStyle],
  );

  const handleMapRecentre = useCallback(() => {
    mapInstance.current?.getMapboxMap().easeTo({
      center: map_primary.center,
      zoom: map_primary.zoom.init,
      padding: 0,
    });

    floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);

    handleUserLocationStyle();

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });
  }, [
    floors,
    handleUserLocationStyle,
    map_primary.center,
    map_primary.floor,
    map_primary.zoom.init,
    map_primary.zoom.max,
    map_primary.zoom.min,
  ]);

  const handleSessionTimeout = () => {
    checkOnlineMode();
    setHasInteracted(false);
    setSelectedFeature(null);
    if (!controlsAlwaysLow) dispatch(setMoveDownPopups(false));
    dispatch(setIsStepFreeRoute(false));
    setStepFreeStatus(StepFreeStatus.INACTIVE);
    setCarouselFeatures([]);

    clusteredPinControlInstance.current?.clearFeatureLabels();
    routingControlInstance.current?.clear();

    handleMapRecentre();

    if (searchIsActive) {
      handleTouchEvent(
        InteractionEventTypes.SEARCH_DIALOG_CLOSE,
        !!map_primary?.interaction?.session_logging,
        false,
        {
          event_data: searchTerm,
        },
      );

      setSearchIsActive(false);
      setSearchTerm("");
    }

    if (isShareModalOpen) setIsShareModalOpen(false);
  };

  const { handleTouchEvent, isInactive, resetTimeouts } = useSession(
    map_primary.interaction.session_timeout,
    map_primary.interaction.session_timeout_notice,
    handleSessionTimeout,
    screen_id,
  );

  const handleRouteRequest = useCallback(
    (stepFree: boolean) => {
      if (!routing) {
        const coords = (selectedFeature?.geometry as GeoJSON.Point).coordinates;
        coords &&
          routingControlInstance.current?.handleFitMapToCoordinate(
            coords as LngLatBoundsLike,
          );
        return;
      }

      const { floor, latitude, longitude } =
        data.components.map_primary.you_marker;
      const floorPosition = Object.entries(data.floors).find(
        ([key]) => key === floor,
      );

      if (onlineMode && selectedFeature && floorPosition) {
        routeRequestTrigger({
          from: {
            latitude,
            longitude,
            floorId: floorPosition[1].id,
          },
          to: {
            featureId: Number(selectedFeature.id),
          },
          project: data.project,
          ...(stepFree ? { options: { routeModifier: "step_free" } } : {}),
        });
      }
    },
    [
      data.components.map_primary.you_marker,
      data.floors,
      data.project,
      onlineMode,
      routeRequestTrigger,
      routing,
      selectedFeature,
    ],
  );

  const handleOnTouch = useCallback(
    (eventType: InteractionEventTypes, options?: OnTouchHandlerOptions) => {
      const extraData: { [key: string]: any } = {};

      if (eventType === InteractionEventTypes.ASSET_DIALOG_CLOSE) {
        return resetTimeouts();
      }

      if (
        eventType === InteractionEventTypes.SEARCH_RESULT_OPEN &&
        options?.featureName
      ) {
        extraData.event_data = options.featureName;
      }

      if (eventType === InteractionEventTypes.SEARCH_DIALOG_CLOSE) {
        extraData.event_data = searchTerm;
      }

      if (
        (eventType === InteractionEventTypes.ASSET_DIALOG_OPEN ||
          eventType === InteractionEventTypes.SHARE_TO_MOBILE_ROUTE_VIEW) &&
        options?.featureID
      ) {
        extraData.event_data = options.featureID;
      }

      if (
        eventType === InteractionEventTypes.ROUTE_SHOWN &&
        options?.featureID
      ) {
        extraData.event_data = `${options.featureID}${
          isStepFreeRoute ? "/step-free" : ""
        }`;
      }

      handleTouchEvent(
        eventType,
        !!map_primary?.interaction?.session_logging,
        !(
          eventType === InteractionEventTypes.LOWER_CONTROLS ||
          eventType === InteractionEventTypes.RAISE_CONTROLS ||
          eventType === InteractionEventTypes.ROUTE_SHOWN
        ),
        extraData,
      );

      if (eventType === InteractionEventTypes.RE_CENTER_MAP_TOUCH)
        handleMapRecentre();

      setHasInteracted(true);

      if (
        eventType === InteractionEventTypes.SEARCH_RESULT_OPEN ||
        eventType === InteractionEventTypes.PAN_CONTROL_TOUCH ||
        eventType === InteractionEventTypes.ASSET_DIALOG_OPEN ||
        eventType === InteractionEventTypes.ROUTE_SHOWN
      )
        setLocationBtnActive(false);

      if (eventType !== InteractionEventTypes.LEVEL_SELECTOR_TOUCH) return;

      !selectedFeature &&
        clusteredPinControlInstance.current?.reloadFeatureLabels();

      if (
        floorControlInstance.current?.getActiveFloor()?.id !==
        floors[map_primary.you_marker.floor].id
      ) {
        handleUserLocationStyle(false);
      } else {
        handleUserLocationStyle();
      }
    },
    [
      floors,
      handleMapRecentre,
      handleTouchEvent,
      handleUserLocationStyle,
      isStepFreeRoute,
      map_primary?.interaction?.session_logging,
      map_primary.you_marker.floor,
      resetTimeouts,
      searchTerm,
      selectedFeature,
    ],
  );

  const updateRouteLinePadding = useCallback(() => {
    const paddingAllowance = fullHD ? 50 : 100;

    const paddingX =
      (fullHD
        ? ControlSizeScale[controlSize]
        : ControlSizeScale[controlSize] * 2) + paddingAllowance;
    const paddingY =
      (fullHD
        ? ControlSizeScale[controlSize]
        : ControlSizeScale[controlSize] * 2) + paddingAllowance;

    routingControlInstance.current?.setRouteLinePadding({
      top: paddingY,
      bottom:
        footerHeight * 6 -
        (iframe ? iframe.height : 0) +
        paddingY +
        (moveDownPopups ? 0 : mapControlMarginBottom) +
        (iframe ? iframe.height : 0),
      left: paddingX,
      right: paddingX,
    });
  }, [
    controlSize,
    footerHeight,
    fullHD,
    iframe,
    mapControlMarginBottom,
    moveDownPopups,
  ]);

  const handleKeyboardOnChange = useCallback(
    (value: string) => {
      resetTimeouts(); // Restart session timeouts
      setSearchTerm(value);
    },
    [resetTimeouts],
  );

  const handleTouchMapRecentre = () => {
    handleOnTouch(InteractionEventTypes.RE_CENTER_MAP_TOUCH);
    setLocationBtnActive(true);
  };

  const handleFeatureRouteLoad = () => {
    // If the routeFeatureId is present in the URL, we need to display the route for the given feature
    if (routeFeatureId) {
      // Find feature within the features array
      const feature = features?.find(
        (feature) => feature.properties.lm_id === routeFeatureId,
      );

      if (feature) {
        const lmFeature = createLMFeatures([feature]);
        clusteredPinControlInstance.current?.updateFeatureLabels(
          lmFeature,
          true,
        );
      } else console.warn(`Feature with ID ${routeFeatureId} not found`);

      // Remove the feature query param from the URL after the route has been loaded
      queryParams.delete("feature");
      const newUrl = `${new URL(window.location.href).pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  };

  const handleMapReady = (map: LivingMap) => {
    mapInstance.current = map;
    floorControlInstance.current = map.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );
    positionControlInstance.current = map.getPluginById<PositionPlugin>(
      PLUGIN_IDS.USER_LOCATION,
    );
    clusteredPinControlInstance.current = map.getPluginById<ClusteredPinPlugin>(
      PLUGIN_IDS.CLUSTERED_PIN,
    );
    routingControlInstance.current = map.getPluginById<RoutingPlugin>(
      PLUGIN_IDS.ROUTING,
    );

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });

    handleFeatureRouteLoad();

    setMapIsReady(true);
  };

  const handleResultClick = (features: Feature[]) => {
    const featureName = features[0].properties.popup_header;

    handleOnTouch(InteractionEventTypes.SEARCH_RESULT_OPEN, {
      featureName,
    });

    const bounds = getBoundingBox(features);
    const options: MapOptions["fitBoundsOptions"] = {
      bearing: map_primary.bearing,
      padding: 200,
    };

    features.length > 1 &&
      mapInstance.current?.getMapboxMap().fitBounds(bounds, options);

    const lmFeatures = createLMFeatures(features);

    clusteredPinControlInstance.current?.updateFeatureLabels(lmFeatures, true);
    routingControlInstance.current?.clear();

    if (features.length > 1) {
      const sortedFeatures = sortFeaturesByDistance(
        [location.longitude, location.latitude],
        features,
      );
      setCarouselFeatures(sortedFeatures);
      setSelectedFeature(sortedFeatures[0]);
      clusteredPinControlInstance.current?.updateSearchFeatureLabel(
        sortedFeatures[0],
      );
    } else {
      options.zoom = map_primary.zoom.init; // Stick to the default zoom level if there's only one feature to display
      setSearchTerm("");
    }

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });

    setSearchIsActive(false);
  };

  const handleSearchControlClick = () => {
    setSearchIsActive(true);
    handleOnTouch(InteractionEventTypes.SEARCH_CONTROL_TOUCH);
  };

  const handleSearchInputActive = (state: boolean) =>
    setSearchInputActive(state);

  const handleKeyboardClose = () => {
    setSearchIsActive(false);
    handleOnTouch(InteractionEventTypes.SEARCH_DIALOG_CLOSE);
  };

  const handleZoomPinch = (zoomLevel: number) => zoomUpdate(zoomLevel);
  const handleZoomClick = (zoomLevel: number) => {
    handleOnTouch(InteractionEventTypes.ZOOM_CONTROL_TOUCH);
    zoomUpdate(zoomLevel);
  };

  const handleMoveControls = () => {
    handleOnTouch(
      moveDownPopups
        ? InteractionEventTypes.RAISE_CONTROLS
        : InteractionEventTypes.LOWER_CONTROLS,
    );
  };

  const zoomUpdate = (zoomLevel: number) => {
    const minZoom = map_primary.zoom.min;
    const maxZoom = map_primary.zoom.max;

    if (zoomLevel <= minZoom) {
      setZoomLimitReached({
        min: true,
        max: false,
      });

      return;
    }

    if (zoomLevel >= maxZoom) {
      setZoomLimitReached({
        min: false,
        max: true,
      });

      return;
    }

    setZoomLimitReached({
      min: false,
      max: false,
    });
  };

  useEffect(() => {
    if (!selectedFeature) {
      setStepFreeStatus(StepFreeStatus.INACTIVE);
      return;
    }

    handleRouteRequest(isStepFreeRoute);
    updateRouteLinePadding();
  }, [
    handleRouteRequest,
    isStepFreeRoute,
    selectedFeature,
    updateRouteLinePadding,
  ]);

  useEffect(() => {
    if (!routing) return;

    if (!routeResult.isFetching && routeResult.isSuccess && selectedFeature) {
      const { routeHasSteps } = renderRoute();

      if (routeHasSteps || isStepFreeRoute) {
        setStepFreeStatus(StepFreeStatus.SHOW);
      } else if (!isStepFreeRoute) {
        setStepFreeStatus(StepFreeStatus.HIDE);
      }

      handleUserLocationStyle();

      floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);

      handleOnTouch(InteractionEventTypes.ROUTE_SHOWN, {
        featureID: selectedFeature?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    floors,
    handleUserLocationStyle,
    map_primary.floor,
    renderRoute,
    routeResult.isFetching,
    routeResult.isSuccess,
    routing,
  ]);

  useEffect(() => {
    if (routeResult.isError) setStepFreeStatus(StepFreeStatus.HIDE);
  }, [routeResult.isError]);

  useEffect(() => {
    if (selectedFeature?.id && onlineMode)
      handleOnTouch(InteractionEventTypes.ASSET_DIALOG_OPEN, {
        featureID: selectedFeature?.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature?.id]);

  useEffect(() => {
    if (!mapIsReady) return;
    if (!primaryControls.show.search) {
      return window.removeSplashScreen();
    }

    if (imagesPreloaded || !pre_load_images) {
      window.removeSplashScreen();
    }
  }, [
    mapIsReady,
    imagesPreloaded,
    primaryControls.show.search,
    pre_load_images,
  ]);

  useEffect(() => {
    if (!mapIsReady) return;

    positionControlInstance.current?.updateUserLocationStyle(
      userLocationStyle.colour,
      userLocationStyle.borderColour,
      userLocationStyle.displayPulse,
    );
  }, [
    mapIsReady,
    userLocationStyle.colour,
    userLocationStyle.borderColour,
    userLocationStyle.displayPulse,
  ]);

  useEffect(() => {
    const footer = footerRef?.current;

    if (!footer) return;

    const observer = new ResizeObserver(() => {
      setFooterHeight(footer.clientHeight);
    });

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (countdownTimeInSeconds === 0 || !isInactive) {
      setCountdownTimeInSeconds(noticeTimeout / 1000);

      return;
    }

    const intervalID = setInterval(() => {
      setCountdownTimeInSeconds((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [
    checkOnlineMode,
    countdownTimeInSeconds,
    dispatch,
    isInactive,
    noticeTimeout,
  ]);

  useEffect(() => {
    if (mapControlsAlwaysLow) {
      dispatch(setMoveDownPopups(true));
      dispatch(setControlsAlwaysLow(true));
    }
  }, [dispatch, mapControlsAlwaysLow]);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: body.theme.background_color }}
    >
      <Header
        languages={languages}
        lang={top_bar.lang!}
        theme={top_bar.theme}
        icon={top_bar.icon}
        time={server.time}
        timezone={location.timezone}
      />
      {iframe && (
        <IFrame
          dataQA="iframe-container"
          backgroundColor={
            iframe.theme.background_color ? iframe.theme.background_color : ""
          }
          height={iframe.height}
          position={iframe.position}
          url={iframe.url}
        />
      )}
      <div className={styles.primaryMap}>
        <Map
          mapID={screen_id}
          bearing={map_primary.bearing}
          zoom={map_primary.zoom.init}
          maxZoom={map_primary.zoom.max}
          minZoom={map_primary.zoom.min}
          center={map_primary.center}
          extent={map_primary.extent}
          mapStyle={stylesheet}
          floor={map_primary.floor}
          floors={floors}
          accessToken={mapboxApiKey}
          youMarker={map_primary.you_marker}
          controlTheme={controlTheme}
          controlSize={controlSize}
          interactive={!!map_primary.interaction.enabled}
          enableTouchZoom={primaryControls.show.zoom_controls}
          onTouch={handleOnTouch}
          onZoom={handleZoomPinch}
          onMapReady={handleMapReady}
          onMapDrag={() => {
            setHasInteracted(true);
            setLocationBtnActive(false);
          }}
          featureHorizonHeight={
            primaryControls.theme.feature_horizon_height
              ? primaryControls.theme.feature_horizon_height
              : fullHD
                ? DEFAULT_FEATURE_HORIZON_HEIGHT
                : DEFAULT_FEATURE_HORIZON_HEIGHT * 2
          }
          onFeatureSelect={setSelectedFeature}
          initZoom={map_primary.zoom.init}
          onMoveControls={handleMoveControls}
          // Pass the height of the IFrame to the map only when the IFrame is at the bottom because we don't need to adjust the floating modals when the IFrame is at the top
          iframeHeight={iframe?.position === "bottom" ? iframe?.height : 0}
          defaultFloorId={floors[map_primary.floor].id}
          clearCarouselFeatures={() => setCarouselFeatures([])}
        >
          {features &&
            transitions(
              (transitionStyles, show) =>
                show && (
                  <MoveDownView
                    className={classNames(styles.searchWrapper, {
                      [styles.accessibleHeight]: moveDownPopups,
                    })}
                    onBgClick={handleKeyboardClose}
                  >
                    <animated.div
                      style={transitionStyles}
                      className={styles.animatedWrapper}
                    >
                      <div
                        className={classNames(
                          styles.searchHeightPadder,
                          styles[controlSize],
                        )}
                      >
                        <SearchResults
                          dataQA="search-results"
                          features={features}
                          searchTerm={searchTerm.trim()}
                          onSearchInputActive={handleSearchInputActive}
                          onResultClick={(features) =>
                            handleResultClick(features)
                          }
                          onScroll={throttle(resetTimeouts, 500)}
                          className={classNames(
                            styles.searchResultsContainer,
                            styles[controlSize],
                          )}
                          enableImages={enable_images}
                          floors={floors}
                          defaultFloorId={floors[map_primary.floor].id}
                        />
                      </div>
                      <Keyboard
                        searchTerm={
                          carouselFeatures.length > 1
                            ? carouselFeatures[0].properties?.popup_header
                            : ""
                        }
                        project={project}
                        dataQA="search-keyboard"
                        onClose={handleKeyboardClose}
                        onChange={handleKeyboardOnChange}
                        enableInput={searchInputActive}
                      />
                    </animated.div>
                  </MoveDownView>
                ),
            )}
          {isShareModalOpen && (
            <ShareToMobileModal
              qrCode={handoff_bar.qr_code}
              qrCodeLink={
                selectedFeature
                  ? buildRouteShortlink(selectedFeature?.id, isStepFreeRoute)
                  : null
              }
              onClick={() => {
                setIsShareModalOpen(false);
                resetTimeouts();
              }}
            />
          )}
          {isScreenIdModalOpen && (
            <Modal
              dataQA="screen-id-modal"
              title="Kiosk ID"
              size={controlSize}
              theme={controlTheme}
              className={classNames(styles[controlSize], styles[controlTheme])}
              onBgClick={() => setIsScreenIdModalOpen(false)}
              buttonLabel="Close"
              onButtonClick={() => setIsScreenIdModalOpen(false)}
            >
              <div
                className={classNames(styles[controlSize], styles.modalBody)}
              >
                {screen_id}
              </div>
            </Modal>
          )}
          {isDiagnosticsModalOpen && (
            <Modal
              dataQA="diagnostics-modal"
              title="Diagnostics"
              size={controlSize}
              theme={controlTheme}
              className={classNames(styles[controlSize], styles[controlTheme])}
              onBgClick={() => setIsDiagnosticsModalOpen(false)}
              buttonLabel="Close"
              onButtonClick={() => setIsDiagnosticsModalOpen(false)}
            >
              <div
                className={classNames(
                  styles[controlSize],
                  styles.modalBody,
                  styles.diagnosticsBody,
                )}
              >
                {JSON.stringify(getScreenDiagnostics(), null, "\t")}
              </div>
            </Modal>
          )}
          {primaryControls.show.re_center_map && (
            <CentreControl
              isInactive={isInactive}
              onRecentre={handleTouchMapRecentre}
              onTouch={handleOnTouch}
              hasPanned={hasInteracted && !locationBtnActive}
              countdownTimeInSeconds={countdownTimeInSeconds}
            />
          )}
          {primaryControls.show.north_marker && (
            <div
              className={classNames(
                styles.primaryCompassContainer,
                styles[controlSize],
              )}
            >
              <Compass bearing={map_primary.bearing} />
            </div>
          )}
          <div
            className={styles.mapControlsContainer}
            style={{
              bottom: `${
                moveDownPopups ? 0 : footerHeight + mapControlMarginBottom
              }px`,
            }}
          >
            {/* Left map controls */}
            {(primaryControls.show.zoom_controls ||
              primaryControls.show.pan_control) && (
              <div
                className={classNames(
                  styles.controlContainer,
                  styles.controlLeftContainer,
                  styles[controlSize],
                  { [styles.accessibleHeight]: moveDownPopups },
                )}
              >
                <div>
                  {primaryControls.show.pan_control && (
                    <div
                      className={classNames(
                        styles.controlRightMargin,
                        styles[controlSize],
                      )}
                    >
                      <PanControl
                        onTouch={handleOnTouch}
                        hasPanned={hasInteracted}
                        setHasPanned={setHasInteracted}
                      />
                    </div>
                  )}
                  {primaryControls.show.zoom_controls && (
                    <ZoomControl
                      className={styles.zoomControlContainer}
                      minZoomReached={zoomLimitReached.min}
                      maxZoomReached={zoomLimitReached.max}
                      onZoomClick={handleZoomClick}
                    />
                  )}
                </div>
              </div>
            )}
            {/* Right map controls */}
            {primaryControls.show.level_selector && (
              <div
                className={classNames(
                  styles.controlContainer,
                  styles.controlRightContainer,
                  styles[controlSize],
                  { [styles.accessibleHeight]: moveDownPopups },
                )}
              >
                {primaryControls.show.level_selector && (
                  <FloorSelector
                    dataQA="floor-container"
                    floors={floors}
                    youAreHereFloor={map_primary.you_marker.floor}
                    onTouch={handleOnTouch}
                    poiFloorId={selectedFeature?.properties?.poi_floor_id}
                    activeFloor={
                      floorControlInstance.current?.getActiveFloor() ||
                      map_primary.floor
                    }
                  />
                )}
                {primaryControls.show.re_center_map && (
                  <div
                    className={classNames(
                      styles.controlTopMargin,
                      styles[controlSize],
                    )}
                  >
                    <LocationButton
                      dataQA="location-button"
                      onClick={handleTouchMapRecentre}
                      size={controlSize}
                      status={
                        !hasInteracted || locationBtnActive
                          ? LocationStatus.FOUND
                          : LocationStatus.INACTIVE
                      }
                      theme={controlTheme}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(
              styles.searchControlsContainer,
              styles[controlSize],
            )}
            style={{
              bottom: `${
                moveDownPopups
                  ? footerHeight
                  : footerHeight + mapControlMarginBottom
              }px`,
            }}
          >
            <div
              className={classNames(styles.searchFeaturesContainer, {
                [styles.accessibleHeight]: moveDownPopups,
              })}
            >
              {onlineMode && (
                <div
                  className={classNames(styles.toggleContainer, {
                    [styles.hidden]:
                      !selectedFeature ||
                      stepFreeStatus === StepFreeStatus.INACTIVE,
                  })}
                >
                  <ToggleSwitch
                    className={classNames(styles.toggle, {
                      [styles.hidden]:
                        !routeResult.isSuccess ||
                        stepFreeStatus === StepFreeStatus.HIDE ||
                        routeResult.isError,
                    })}
                    dataQA="step-free-toggle"
                    label="Avoid stairs"
                    isToggled={isStepFreeRoute}
                    onToggle={() => {
                      routingControlInstance.current?.clear();
                      dispatch(setIsStepFreeRoute(!isStepFreeRoute));
                      handleRouteRequest(!isStepFreeRoute);
                      handleOnTouch(
                        isStepFreeRoute
                          ? InteractionEventTypes.AVOID_STAIRS_OFF
                          : InteractionEventTypes.AVOID_STAIRS_ON,
                      );
                    }}
                  />
                  <StepFreeChip
                    dataQA="step-free-chip"
                    className={classNames({
                      [styles.hidden]: stepFreeStatus === StepFreeStatus.SHOW,
                    })}
                    success={!routeResult.isError}
                  />
                </div>
              )}
              {features && primaryControls.show.search && (
                <div className={styles.searchContainer}>
                  <SearchControl
                    dataQA="search"
                    buttonStyle="smallSquare"
                    className={styles.smallSearchControlContainer}
                    onClick={handleSearchControlClick}
                    project={project}
                    searchTerm={
                      carouselFeatures.length > 1
                        ? carouselFeatures[0].properties?.popup_header
                        : ""
                    }
                    hasInteracted={hasInteracted}
                    multipleResults={carouselFeatures.length > 1}
                    onClearClick={() => {
                      setSelectedFeature(null);
                      setCarouselFeatures([]);
                      routingControlInstance.current?.clear();
                      clusteredPinControlInstance.current?.clearFeatureLabels();
                    }}
                  />
                </div>
              )}
              {(carouselFeatures.length > 1 || selectedFeature) && (
                <div
                  className={classNames(styles.poiContainer, {
                    [styles.accessibleHeight]: moveDownPopups,
                  })}
                >
                  {carouselFeatures.length > 1 ? (
                    <SearchResultsCarousel
                      dataQA="search-results-carousel"
                      features={carouselFeatures}
                      className={classNames(styles.carousel, {
                        [styles.accessibleHeight]: moveDownPopups,
                      })}
                      floors={floors}
                      defaultFloorId={floors[map_primary.floor].id}
                      selectedFeature={selectedFeature}
                      onSlideChange={(feature) => {
                        clusteredPinControlInstance.current?.updateSearchFeatureLabel(
                          feature,
                        );
                      }}
                      isStepFreeRoute={isStepFreeRoute}
                    />
                  ) : selectedFeature ? (
                    <AssetInformation
                      className={classNames(styles.singlePoi, {
                        [styles.accessibleHeight]: moveDownPopups,
                      })}
                      dataQA="asset-information"
                      onClose={() => {
                        clusteredPinControlInstance.current?.clearSelectedFeature();
                        handleOnTouch(InteractionEventTypes.ASSET_DIALOG_CLOSE);
                        setSelectedFeature(null);
                        routingControlInstance.current?.clear();
                        clusteredPinControlInstance.current?.clearFeatureLabels();
                      }}
                      name={selectedFeature.properties?.popup_header}
                      category={selectedFeature.properties?.popup_subheader}
                      building={selectedFeature.properties?.location_name}
                      operatingHours={formattedOperatingHours}
                      theme={{
                        mode: controlTheme,
                        size: controlSize,
                      }}
                      qrCodeLink={codeLink}
                      floorName={selectedFeature.properties?.floor_name}
                      isTemporarilyClosed={
                        selectedFeature.properties?.temporarily_closed
                      }
                      imageSrc={selectedFeature.properties?.popup_image_url}
                      floorChange={{
                        direction,
                        amount,
                      }}
                      kilometres={routeResult.data?.routeMetadata.reduce(
                        (acc, curr) => acc + curr.totalLength,
                        0,
                      )}
                      showQRCode
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.footerContainer, styles[controlSize])}
          >
            <div className={styles.quickSearchTagsContainer} />
            <div className={styles.attributionShareContainer} ref={footerRef}>
              <div className={styles.content}>
                <div
                  className={classNames(
                    styles.buttons,
                    styles.showLowerControls,
                  )}
                >
                  {handoff_bar && (
                    <Button
                      dataQA="share-to-mobile"
                      onClick={() => {
                        setIsShareModalOpen(true);
                        handleOnTouch(
                          selectedFeature
                            ? InteractionEventTypes.SHARE_TO_MOBILE_ROUTE_VIEW
                            : InteractionEventTypes.SHARE_TO_MOBILE_KIOSK_VIEW,
                          selectedFeature
                            ? { featureID: selectedFeature.id }
                            : {},
                        );
                      }}
                      label="Share to mobile"
                      color="black"
                      {...(controlTheme === "light" && {
                        outlined: true,
                      })}
                      leftIcon="ShareDirectionsIcon"
                      rounded
                      size={controlSize}
                      className={classNames(
                        styles.shareButton,
                        styles.showLowerControls,
                        { [styles.alwaysLowControls]: controlsAlwaysLow },
                      )}
                      theme={controlTheme}
                    />
                  )}
                  {!controlsAlwaysLow && (
                    <MoveDownButton
                      color="black"
                      classNames={styles.moveControlsButton}
                      outlined={controlTheme === "light"}
                    />
                  )}
                </div>
                <div className={styles.attribution}>
                  <Icon
                    className={styles.logo}
                    dataQA="attribution-logo"
                    type="LmLogomarkIcon"
                  />
                  <span>&copy; Living Map</span>
                  <span>&copy; OpenStreetMap</span>
                </div>
              </div>
            </div>
          </div>
        </Map>
      </div>
    </div>
  );
};

export default BaseWithHeader;
